import type { UseChipOptions } from './../types';

export function useChip(options: UseChipOptions) {
  const { modelValue, props } = options;

  const defaultClassList = computed(() => ({
    active: modelValue.value?.includes(props.name),
  }));
  /**
   * Updates the model value based on the provided name.
   * If the model value is not an array, it sets it to the provided name.
   * If the model value is an array, it toggles the presence of the provided name in the array.
   * @param {string} name - The name to update the model value with.
   * @returns {void}
   */
  function updateModelValue(name: string) {
    if (Array.isArray(modelValue.value)) {
      if (modelValue.value?.includes(name)) {
        modelValue.value = modelValue.value?.filter(value => value !== name);
      } else {
        modelValue.value?.push(name);
      }
    } else {
      if (modelValue.value === name) {
        return;
      }
      modelValue.value = name;
    }
  }
  return {
    defaultClassList,
    updateModelValue,
  };
}
